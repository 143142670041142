/* Do not show the markup inside web components until the corresponding web
 * component has been loaded */
:not(turbo-frame):not(x-slider):not(x-toggle):not(x-dispatch):not([eventname='mobile-sidebar']):not(:defined) {
  display: none;
}

x-toggle:not(:defined) > *:not([slot='default']) {
  display: none;
}

:root {
  --swiper-theme-color: #2196f3;
}

.noUi-connect {
  background: currentColor;
}

x-price-filter {
  display: block;
  min-height: 84px;
}

/* doesnt work yet */
.turbo-progress-bar ~ button.suggest-button::before {
  content: '🕑';
  padding-right: 8px;
}

.turbo-progress-bar ~ button.suggest-button {
  pointer-events: none;
}

.turbo-progress-bar {
  height: 32px;
  opacity: 0.5;
  background: rgb(11, 97, 238);
  background: linear-gradient(
    90deg,
    rgba(11, 97, 238, 1) 0%,
    rgba(0, 212, 255, 1) 50%,
    rgba(141, 236, 255, 1) 88%,
    rgba(185, 244, 255, 1) 100%
  );
}
